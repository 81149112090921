@import "../../styles/Scss/main";

.overview-personal-info {
  margin-top: 24px;
  margin-bottom: 40px;
  margin-right: 10px;
}

.profile-img {
  max-width: 300px;
  width: 100%;
  padding: 30px !important;
}

.user-data {
  margin-bottom: 5px;

  i {
    font-size: 20px;
    margin-right: 6px;
  }
}

.trainerStat { 
  height: 100%;
  padding: 20px; 

  .trainerStatFirstDiv{
    height: 50%;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px;
    
    .sessionStyle {
      width: 33%;
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      cursor: pointer;
      p {text-align:center;}
    }
    .retentionStyle {
      flex-direction:column;
      justify-content: center;
      align-items: center; 
      position: relative;
      p {text-align:center; margin-top: 10px;}}
    .progressStyle {
      width:34%; 
      height:50px; 
      margin-bottom:50px;
      .CircularProgressbar {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
      } 
    }
  }
}

.signin-type{
padding: 8px;
width: 75%;
color: white;
font-weight: bold;
text-align: center;
}
.google-signin-color{
  background: rgba(255, 0, 0, 0.8);
}
.facebook-signin-color{
  background: #3578e5;
}
.apple-signin-color{
  background: black;
}
.credential-signin-color{
  background: #32c5d2;
}